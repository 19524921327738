import { Injectable } from "@angular/core";
import { ApiService } from './api.services';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: 'root'
})
export class AppsService {
	public readonly marketing = [
		{
			appName: 'CMS',
			appUrl: 'www.fsiservices.com/cms-core',
			image: 'assets/CMS.png'
		},
		{
			appName: 'Admin Console',
			appUrl: 'www.fsiservices.com/cms-core',
			image: 'assets/Admin.png'
		},
		{
			appName: 'Call Center',
			appUrl: 'www.fsiservices.com/cms-core',
			image: 'assets/Call-Center.png'
		},
		{
			appName: 'Discussion Board',
			appUrl: 'www.fsiservices.com/cms-core',
			image: 'assets/Discussion.png'
		},
		{
			appName: 'Rounding',
			appUrl: 'www.fsiservices.com/cms-rounding',
			image: 'assets/Rounding.png'
		},
		{
			appName: 'Space Manager',
			appUrl: 'www.fsiservices.com/cms-space-manager',
			image: 'assets/Spaces.png'
		},
		{
			appName: 'Tech',
			appUrl: 'www.fsiservices.com/cms-tech',
			image: 'assets/Tech.png'
		},
		{
			appName: 'View',
			appDescription: 'CMS View',
			appUrl: 'www.fsiservices.com/cms-view',
			image: 'assets/View.png'
		}
	];

	public apps: any[];
	public availableApps: any[];
	public unavailableApps: any[];

	constructor(
		private auth: AuthService,
		private api: ApiService
	) { }

	public fetch(): Observable<boolean> {
		return zip(this.getApps(), this.getAvailableApps()).pipe(
			map(([apps, availableApps]) => { 
				this.apps = apps;
				this.availableApps = availableApps.map(app => {
					app.image = this.getImage(app.appName);
					return app;
				});
				this.unavailableApps = apps.filter(app => availableApps
					.find(a => a.appMasterID == app.appMasterID) == null)
					.map(app => {
						app.appUrl = this.getMarketingUrl(app.appName);
						app.image = this.getImage(app.appName);
						return app;
					});

				if(!availableApps.find(app => app.appName == 'Tech' || app.appName == 'Rounding')){
					let marketing = <any>this.marketing.find(m => m.appName == 'View');
					if(marketing){
						this.unavailableApps.push(marketing);
					}
				}

				return true;
			})
		);
	}

	public getApps(): Observable<any[]> {
		return this.api.get<any[]>('apps');
	}

	public getAvailableApps(): Observable<any[]> {
		return this.api.get<any[]>('apps/available');
	}

	public getMarketingUrl(appName: string): string {
		let marketing = this.marketing.find(m => m.appName == appName);

		if (marketing && marketing.appUrl) {
			return marketing.appUrl;
		}

		return this.marketing.find(m => m.appName == 'CMS')?.appUrl;
	}
    
	public getImage(appName: string): string {
		let marketing = this.marketing.find(m => m.appName == appName);

		if (marketing && marketing.image) {
			return marketing.image;
		}

		return this.marketing.find(m => m.appName == 'CMS')?.image;
	}
}
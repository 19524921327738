import { Component, OnInit } from "@angular/core";
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApiService } from "../services/api.services";
import { AuthService } from '../services/auth.service';
import { AppsService } from '../services/apps.service';

@Component({
	templateUrl: 'home-page.component.html'
})
export class HomePageComponent implements OnInit {
	public isAppDrawerOpen: boolean = false;
	public showNotification: boolean = false;
	public username: string = "";
  
	constructor(
		public auth: AuthService,
		public appService: AppsService,
		private api: ApiService,
		private oidcSecurityService: OidcSecurityService
	){}

	public ngOnInit(): void {
		this.oidcSecurityService.getPayloadFromIdToken().subscribe((payload) => {
			let siteCode = payload?.site;
			this.username = payload?.name;

			if(siteCode){
				this.api.get<any>(`apps/loginnotification`).subscribe(
					(data: any[]) => {
						if (data && data.length > 0) {
							data.forEach((notification) => {
								if(data && notification.loginNotificationShow && notification.loginNotificationText){
									this.showNotification = true;
									setTimeout(() => {//some classics never die
										document.getElementById('loginNotification').innerHTML = notification.loginNotificationText;
									});
								}
							});
						}
					});
			}
		});
	}

	public signOut(): void {
		this.oidcSecurityService.logoff().subscribe();
	}
}

import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public siteCode: string = "";
	public token: string = "";
	public username: string = "";
	public customerId: number;
	public segmentId: number;
	public userId: string;
	public fullName: string;
	public role: string;
	public roleId: number;
	public loading: boolean = false;
	private _cmsSegmentId: number = null;
	private _sessionId: string = null;


	constructor(
		private http: HttpClient,
		private endpoints: EndpointService) {}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { EndpointService } from './endpoint.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(
		private endpoints: EndpointService,
		private http: HttpClient,
		private auth: AuthService) { }

	public get<T>(url: string, segmentId: number = null): Observable<T> {
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.set('Authorization', `Bearer ${this.auth.token}`);
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('SiteCode', this.auth.siteCode);
        
		if (segmentId) {
			headers = headers.set('CmsSegmentId', segmentId.toString());
		}

		return this.http.get<T>(`${this.endpoints.api}/${url}`, {headers});
	}

	public post<T>(url: string, body: any = null): Observable<T> {
		return this.http.post<T>(`${this.endpoints.api}/${url}`, body, {
			headers: {
				Authorization: `Bearer ${this.auth.token}`,
				'Content-Type': 'application/json',
				SiteCode: this.auth.siteCode
			}
		});
	}

	public put<T>(url: string, body: any): Observable<T> {
		return this.http.put<T>(`${this.endpoints.api}/${url}`, body, {
			headers: {
				Authorization: `Bearer ${this.auth.token}`,
				'Content-Type': 'application/json',
				SiteCode: this.auth.siteCode
			}
		});
	}

	public delete<T>(url: string): Observable<T> {
		return this.http.delete<T>(`${this.endpoints.api}/${url}`, {
			headers: {
				Authorization: `Bearer ${this.auth.token}`,
				'Content-Type': 'application/json',
				SiteCode: this.auth.siteCode
			}
		});
	}
}
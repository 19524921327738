<div class="box-container">
    <mat-toolbar class="" style="background-color: var(--fsi-color-blue); color:var(--fsi-color-white); align-items: center;" >
        <span style="flex:1 1 auto; text-align: left;">
            <img src="../../assets/fsi-logo-white.svg" style="max-height: 45px; display: flex;" alt="FSI Logo"/>  
        </span>
        <fsi-app-switcher [appItems]="appService.availableApps"></fsi-app-switcher>
                        
        <fsi-user-profile-menu
            [fullName]="auth.fullName"
            [userName]="auth.username"
            (onSignOutLinkClicked)="signOut()"
        ></fsi-user-profile-menu>
    </mat-toolbar>  
    <div class="flex-row" style="height:100%;">
        <app-card style="max-width: 540px; margin-top:50px;"></app-card>
        <div class="homepage-layout--hero">
            <div class="flex-row" style="height:100%;" >
                <div *ngIf="showNotification" class="notification-area" style="align-self: end;">
                    <div class="flex-row" style="justify-content: center;">
                        <div id="loginNotification" style="max-width: 540px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
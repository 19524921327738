import { Injectable } from "@angular/core";

@Injectable()
export class EndpointService{
	public api: string;
	public loginNotificationApp: string;
	public cms: string;
	public cmsTech: string;
	public rounding: string;
	public callCenter: string;
	public adminConsole: string;
	public spaceManagement: string;
	public discussionBoard: string;
	public cmsMarketing: string;
	public cmsTechMarketing: string;
	public roundingMarketing: string;
	public callCenterMarketing: string;
	public adminConsoleMarketing: string;
	public spaceManagementMarketing: string;
	public discussionBoardMarketing: string;
}
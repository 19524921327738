import { Component, OnInit } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { AppConfigService } from './services/app-config.service';
import { AuthService } from './services/auth.service';
import { AppsService } from './services/apps.service';
import { AlertService } from './services/alert.service';
import { interval } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	public loading: boolean = true;

	constructor(
		public auth: AuthService,
		private appConfig: AppConfigService,
		private oidcSecurityService: OidcSecurityService,
		private router: Router,
		private appsService: AppsService,
		private alert: AlertService) { }

	public ngOnInit(): void {
		const authOptions = { customParams: { 'ClientApp': this.appConfig.appName, 'ClientAppVersion': this.appConfig.appVersion } };
		this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
			if (loginResponse?.isAuthenticated) {
				this.auth.token = loginResponse.accessToken; 
				this.auth.siteCode = loginResponse.userData?.site;
				this.auth.username = loginResponse.userData?.name;
				this.auth.userId = loginResponse.userData?.userId;
				this.auth.fullName = loginResponse.userData?.fullName;
				this.auth.customerId = Number.parseInt(loginResponse.userData?.customer);
				this.auth.segmentId = Number.parseInt(loginResponse.userData?.defaultSegmentId);
				this.auth.role = loginResponse.userData?.role;
				this.auth.roleId = Number.parseInt(loginResponse.userData?.roleId);

				this.appsService.fetch().subscribe(
					() => {
						console.log(this.appsService.unavailableApps);
						this.loading = false;
						let route = sessionStorage.getItem('route');

						sessionStorage.removeItem('route');
						if (route && route != '/') {
							this.router.navigateByUrl(route);
						} else {
							this.router.navigateByUrl('/home');
						}
					}, () => {
						this.alert.error('An error occurred while getting apps');
					}
				);
			} else {
				sessionStorage.setItem("route", this.router.url);
				this.oidcSecurityService.authorize(null, authOptions);
			}
		});


		interval(30000).subscribe(
			() => {
				this.oidcSecurityService.checkAuthIncludingServer().subscribe(
					(auth) =>{
						if(!auth){
							sessionStorage.setItem("route", this.router.url);
							this.oidcSecurityService.authorize(null, authOptions);
						}
					}
				);
			}
		);
	}
}

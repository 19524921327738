/* eslint-disable arrow-body-style */
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
	AuthModule,
	StsConfigHttpLoader,
	StsConfigLoader,
} from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';

export const httpLoaderFactory = (httpClient: HttpClient) => {
	const authConfig = httpClient
		.get<any>(
		'./auth-config.json'
	)
		.pipe(
			map((customConfig: any) => {
				return {
					authority: customConfig.authority,
					clientId: customConfig.client_id,
					scope: customConfig.scope,
					redirectUrl: window.location.origin,
					postLogoutRedirectUri: window.location.origin,
					responseType: 'id_token token',
					disableIatOffsetValidation: true,
					disableRefreshIdTokenAuthTimeValidation: true,
					autoUserInfo: false
				};
			})
		);

	return new StsConfigHttpLoader(authConfig);
};

@NgModule({
	imports: [
		AuthModule.forRoot({
			loader: {
				provide: StsConfigLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	exports: [AuthModule],
})
export class AuthConfigModule {}

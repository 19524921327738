import { Component } from "@angular/core";
import { EndpointService } from '../services/endpoint.service';
import { AppsService } from '../services/apps.service';

@Component({
	selector: 'app-card',
	templateUrl: 'app-card.component.html'
})
export class AppCardComponent{
	constructor(
		public endpoints: EndpointService,
		public appsService: AppsService
	) { }
}
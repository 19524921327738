import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(public oidcSecurityService: OidcSecurityService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let token = '';
		this.oidcSecurityService.getAccessToken().subscribe((accessToken) => {
			token = accessToken;

			if(token){
				let siteCode = '';
				this.oidcSecurityService.getPayloadFromIdToken().subscribe((payload) => {
					siteCode = payload.site;
				});

				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
						Site: siteCode
					}
				});
			}

		});
		return next.handle(request);
	}
}

<div class="row side-padding">
    <h2>My Applications</h2>
</div>
<div class="row side-padding" style="margin-top:20px;">
    <div class="flex-row" style="justify-content:left;">
        <a *ngFor="let app of appsService.availableApps" [href]="'https://'+app.appUrl" target="_blank">
            <button class="btn-rise square">
                <img [src]="app.image" height="50px" />
                <div class="btn-square-label">{{app.appDescription}}</div>
            </button>
        </a>
    </div>
</div>
<div *ngIf="appsService.unavailableApps && appsService.unavailableApps.length" class="row side-padding">
    <h2>Additional Products</h2>
</div>
<div *ngIf="appsService.unavailableApps && appsService.unavailableApps.length" class="row side-padding" style="margin-top:20px;">
    <div class="flex-row" style="justify-content: left;">
        <a *ngFor="let app of appsService.unavailableApps" [href]="'https://'+app.appUrl" target="_blank">
            <button class="btn-rise square grayscale">
                <img [src]="app.image" height="50px" />
                <div class="btn-square-label">{{app.appDescription}}</div>
            </button>
        </a>
    </div>
</div>
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AppCardComponent } from './app-card/app-card.component';
import { TokenInterceptor } from './services/token.interceptor';
import { AngularMaterialModule } from './angular-material.module';
import { EndpointService } from './services/endpoint.service';
import { AppConfigService } from './services/app-config.service';
import { AuthConfigModule } from './auth-config.modal';
import {
	LoadingIndicatorComponentModule,
	NavigationComponentModule,
	UserProfileMenuComponentModule,
	CustomerLogoComponentModule,
	FacilitySwitcherComponentModule,
	AppSwitcherComponentModule
} from '@fsi/fsi-component-lib';

export function configureEndpoints(endpoints: EndpointService, http: HttpClient) {
	return () => {
		return new Promise(
			(resolve, reject) => {
				http.get('./endpoints.json').toPromise().then(
					(data: any)=> {
						endpoints.api = data.api;
						endpoints.loginNotificationApp = data.loginNotificationApp;
						endpoints.cms = data.cms;
						endpoints.cmsTech = data.cmsTech;
						endpoints.rounding = data.rounding;
						endpoints.callCenter = data.callCenter;
						endpoints.adminConsole = data.adminConsole;
						endpoints.spaceManagement = data.spaceManagement;
						endpoints.discussionBoard = data.discussionBoard;
						endpoints.cmsMarketing = data.cmsMarketing;
						endpoints.cmsTechMarketing = data.cmsTechMarketing;
						endpoints.roundingMarketing = data.roundingMarketing;
						endpoints.callCenterMarketing = data.callCenterMarketing;
						endpoints.adminConsoleMarketing = data.adminConsoleMarketing;
						endpoints.spaceManagementMarketing = data.spaceManagementMarketing;
						endpoints.discussionBoardMarketing = data.discussionBoardMarketing;
						resolve(endpoints);
					}).catch((error)=> reject(error));
			}
		);
	};
}

export function configureAppConfig(appConfig: AppConfigService, http: HttpClient) {
	return () => {
		return new Promise(
			(resolve, reject) => {
				http.get('./app-config.json').toPromise().then(
					(data: any)=> {
						appConfig.appName = data.appName;
						appConfig.appVersion = data.appVersion;
						resolve(appConfig);
					}).catch((error)=> reject(error));
			}
		);
	};
}

const routes: Routes = [
	{ path: 'home', component: HomePageComponent }
];

@NgModule({
	declarations: [
		AppComponent,
		HomePageComponent,
		AppCardComponent
	],
	imports: [
		AuthConfigModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		RouterModule.forRoot(routes, {}),
		ReactiveFormsModule,
		HttpClientModule,
		AngularMaterialModule,
		LoadingIndicatorComponentModule,
		NavigationComponentModule,
		UserProfileMenuComponentModule,
		CustomerLogoComponentModule,
		FacilitySwitcherComponentModule,
		AppSwitcherComponentModule
	],
	providers: [
		EndpointService,
		{
			provide: APP_INITIALIZER,
			useFactory: configureEndpoints,
			deps: [EndpointService, HttpClient],
			multi: true
		},
		AppConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: configureAppConfig,
			deps: [AppConfigService, HttpClient],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
